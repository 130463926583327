import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Cookies from "js-cookie";
import { reconnect } from "./DataSource";

export function Login() {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        navigate("/");
    }, []);

    console.log(`Location, ${JSON.stringify(location, null, 2)}`);
    const pathComponents = location.pathname.split("/");
    if (pathComponents.length >= 3) {
        Cookies.set("access-token", pathComponents[2], { expires: 365 });
        reconnect();
    }
    return <div>Login...</div>;
}
