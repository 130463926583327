import { PositionBalanceContentType, StatsType } from "./ex-types";

function tricolor(
    val: number,
    negColor: string = "#C63C51",
    posColor: string = "#519872",
    zeroColor: string = "#547388",
) {
    if (val < 0) {
        return negColor;
    } else if (val > 0) {
        return posColor;
    } else {
        return zeroColor;
    }
}

function formatNumber(
    num: number | undefined,
    prefix: string = "",
    postfix: string = "",
) {
    if (num === undefined) {
        return <span className="text-slate-200">-</span>;
    }
    const numParts = num.toFixed(4).split(".");
    const intPart = numParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const decPart = numParts[1];
    let els = (
        <span>
            {intPart}
            <sub className="text-slate-300">&#8226;{decPart}</sub>
        </span>
    );
    if (prefix.length > 0) {
        els = (
            <span>
                {prefix}&nbsp;{els}
            </span>
        );
    }
    if (postfix.length > 0) {
        els = (
            <span>
                {els}&nbsp;{postfix}
            </span>
        );
    }
    return els;
}

function GaugeElement(props: {
    name: string;
    value: number;
    coloring: boolean | [string, string];
    last?: boolean;
    symbol?: string;
}) {
    const { name, value, coloring, last, symbol } = props;
    const mainClasses =
        "p-1 pl-2 pr-2 flex flex-col border-2 border-gray-700 justify-between" +
        (last ? " border-l-0" : "");
    let style = {};
    if (coloring) {
        if (Array.isArray(coloring)) {
            style = {
                backgroundColor: tricolor(value, coloring[0], coloring[1]),
            };
        } else {
            style = {
                backgroundColor: tricolor(value),
            };
        }
    }
    return (
        <div className={mainClasses} style={style}>
            <div className="text-xs font-bold text-slate-300">{name}</div>
            <div className="text-lg font-bold">
                {symbol}
                &nbsp;
                {formatNumber(value)}
            </div>
        </div>
    );
}

export function MainGauge(props: { stats: StatsType }) {
    const stats = props.stats;

    return (
        <div className="flex flex-row flex-wrap p-1 m-1 text-align-center rounded align-middle border-2 border-gray-600 bg-slate-800">
            <div className="p-1 rotate-[270deg] text-base font-bold w-12">
                Wallet&nbsp;
            </div>
            <GaugeElement
                name="uPNL"
                value={stats.walletBalance["BNFCR"].unrealizedProfit}
                coloring={true}
                symbol="$"
            />
            <GaugeElement
                name="BALANCE"
                value={stats.walletBalance["BNFCR"].walletBalance}
                coloring={false}
                symbol="$"
            />
            <GaugeElement
                name="MARGIN BAL"
                value={stats.walletBalance["BNFCR"].marginBalance}
                coloring={false}
                symbol="$"
                last={true}
            />
        </div>
    );
}

export function AssetGauge(props: {
    position: PositionBalanceContentType;
    symbol: string;
}) {
    const { position, symbol } = props;
    return (
        <div className="flex flex-row flex-wrap p-1 m-1 text-align-center rounded align-middle border-2 border-gray-600 bg-slate-800">
            <div className="p-1 text-wrap text-xs font-bold w-12 break-words">
                {symbol}
            </div>
            <GaugeElement
                name="PRICE"
                value={position.price}
                coloring={false}
                symbol="$"
            />
            <GaugeElement
                name="uPNL"
                value={position.unrealizedProfit}
                coloring={true}
                symbol="$"
            />
            <GaugeElement
                name="POSITION"
                value={position.positionAmt}
                coloring={["#602080", "#8B9A46"]}
            />
            <GaugeElement
                name="ENTRY PRICE"
                value={position.entryPrice}
                coloring={false}
                symbol="$"
            />
            <GaugeElement
                name="MAINT MARGIN"
                value={position.maintMargin}
                coloring={false}
                symbol="$"
                last={true}
            />
        </div>
    );
}
