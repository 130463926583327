import React, { useState, useEffect } from "react";
import "./App.css";
import { LineChart } from "./LineChart";
import type { ChartData } from "./LineChart";
import {
    filterToday,
    subscribeToHistoricalData,
    subscribeToInstantData,
    unsubscribeFromHistoricalData,
    unsubscribeFromInstantData,
} from "./DataSource";
import { Gauges } from "./Gauges";
import { StatsType } from "./ex-types";

function last(arr: any[]) {
    return arr[arr.length - 1];
}

function chartData(data: unknown[], selector: (p: any) => any): ChartData[] {
    return data.map((entry: any) => ({
        date: new Date(entry.ts),
        value: selector(entry),
    }));
}

export function Main() {
    // const [uPnlChartData, setUPnlChartData] = useState<ChartData[]>([]);
    // const [balanceData, setBalanceData] = useState<ChartData[]>([]);
    // const [a1Data, setA1Data] = useState<ChartData[]>([]);
    // const [a2Data, setA2Data] = useState<ChartData[]>([]);
    // const [a1a2Data, setA1a2Data] = useState<ChartData[]>([]);
    // const [assets, setAssets] = useState<string[]>(["-", "-"]);
    const [isDataAvailable, setDataAvailable] = useState(false);

    const updateInstant = (data: StatsType | undefined) => {
        if (data) {
            setDataAvailable(true);
        }
    }

    useEffect(() => {
        subscribeToInstantData(updateInstant);
        return () => {
            console.log("Unsubscribing");
            unsubscribeFromInstantData(updateInstant);
        };
    }, []);

    if (!isDataAvailable) {
        return (
            <div className="flex flex-row justify-center items-center w-screen h-screen">
                <div
                    className="flex p-5 pt-10 pb-10 text-align-center rounded align-middle border-2 border-gray-600"
                    style={{ backgroundColor: "#C63C51" }}
                >
                    <div className="text-8xl font-bold text-slate-700">
                        ス<br />ズ<br />メ
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="flex flex-col w-screen h-screen">
            <Gauges />
            {/* <div className="w-screen h-96">
                <LineChart labels={["uPNL"]} data={[uPnlChartData]} />
            </div>
            <div className="w-screen h-96">
                <LineChart labels={["balance"]} data={[balanceData]} />
            </div>
            <div className="w-screen h-96">
                <LineChart labels={[assets[0]]} data={[a1Data]} />
            </div>
            <div className="w-screen h-96">
                <LineChart labels={[assets[1]]} data={[a2Data]} />
            </div>
            <div className="w-screen h-96">
                <LineChart
                    labels={[`${assets[0]}/${assets[1]}`]}
                    data={[a1a2Data]}
                />
            </div> */}
        </div>
    );
}
