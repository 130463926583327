import ReconnectingWebSocket, { ErrorEvent } from "reconnecting-websocket";
import Cookies from "js-cookie";
import pako from "pako";
import { Buffer } from "buffer";
import { AccountEventType, StatsType } from "./ex-types";

// const WSURL = "ws://localhost:1222/stats";
// const sessionIds = ["ts-2"];

// const WSURL = "wss://api.csuriz.359.wtf/stats";
// const sessionIds = ["ts-2"];

const WSURL = "wss://api.suzume.359.wtf/stats";
const sessionIds = ["ts-2"];

let socket: ReconnectingWebSocket | undefined = undefined;
let historical: StatsType[] = [];
let accountEvents: AccountEventType[] = [];

const historicalCallbacks: ((d: StatsType[]) => void)[] = [];
const instantCallbacks: ((d: StatsType | undefined) => void)[] = [];

export function subscribeToHistoricalData(callback: (d: StatsType[]) => void) {
    historicalCallbacks.push(callback);
}

export function subscribeToInstantData(
    callback: (d: StatsType | undefined) => void,
) {
    instantCallbacks.push(callback);
}

export function unsubscribeFromHistoricalData(
    callback: (d: StatsType[]) => void,
) {
    const index = historicalCallbacks.indexOf(callback);
    if (index > -1) {
        historicalCallbacks.splice(index, 1);
    }
}

export function unsubscribeFromInstantData(
    callback: (d: StatsType | undefined) => void,
) {
    const index = instantCallbacks.indexOf(callback);
    if (index > -1) {
        instantCallbacks.splice(index, 1);
    }
}

function notifyHistoricalData(data: StatsType[]) {
    for (const callback of historicalCallbacks) {
        callback(data);
    }
}

function notifyInstantData(data: StatsType | undefined) {
    for (const callback of instantCallbacks) {
        callback(data);
    }
}

export function filterToday(data: StatsType[]): StatsType[] {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return data.filter((entry: any) => new Date(entry.ts) >= today);
}

function decompress(data: string) {
    const inputBuffer = Buffer.from(data, "base64");
    const unzipped = pako.ungzip(inputBuffer, { to: "string" });
    return JSON.parse(unzipped);
}

export function reconnect() {
    if (socket !== undefined) {
        socket.close();
        historical = [];
    }
    const accessToken = Cookies.get("access-token");
    if (accessToken === undefined) {
        console.log("Not logged in");
        return;
    }
    socket = new ReconnectingWebSocket(
        `${WSURL}?access-token=${accessToken}`,
        undefined,
        {
            startClosed: false,
            maxReconnectionDelay: 2000,
            minReconnectionDelay: 1000,
        },
    );
    socket.addEventListener("open", () => {
        console.log("Connection established");
        if (socket !== undefined) {
            socket.send(JSON.stringify({ type: "stats", sessionIds }));
        }
    });
    socket.addEventListener("close", () => {
        console.log("Connection closed");
        historical = [];
        notifyHistoricalData(historical);
        notifyInstantData(undefined);
    });
    socket.addEventListener("error", (error: ErrorEvent) => {
        console.log("Connection error", error);
        historical = [];
        notifyHistoricalData(historical);
        notifyInstantData(undefined);
    });
    socket.addEventListener("message", (event: any) => {
        // console.log("Message from server ", event.data);
        const data = JSON.parse(event.data);
        if (data.type === "accumulated") {
            const decompressed = decompress(data.compressed);
            historical = decompressed;
            console.log(`Got accumulated data: ${historical.length}`);
            notifyHistoricalData(historical);
        }
        if (data.type === "add") {
            const decompressed = decompress(data.compressed);
            historical.push(decompressed);
            notifyHistoricalData(historical);
        } else if (data.type === "update") {
            const decompressed = decompress(data.compressed);
            // console.log("update:", decompressed);
            notifyInstantData(decompressed);
        } else if (data.type === "accountEventAccumulated") {
            console.log("accountEventAccumulated", decompress(data.compressed));
            accountEvents = decompress(data.compressed);
        } else if (data.type === "accountEventAdd") {
            console.log("accountEventAdd", decompress(data.compressed));
            accountEvents.push(decompress(data.compressed));
        }
    });
}

reconnect();
