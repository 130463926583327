import { useEffect, useState } from "react";
import {
    subscribeToInstantData,
    unsubscribeFromInstantData,
} from "./DataSource";
import { StatsType } from "./ex-types";
import { AssetGauge, MainGauge } from "./MainGauge";

export function Gauges() {
    const [isDataAvailable, setDataAvailable] = useState(false);
    const [latestData, setLatestData] = useState<StatsType | undefined>(
        undefined,
    );

    const updateInstant = (data: StatsType | undefined) => {
        if (data) {
            setLatestData(data);
        }
        setDataAvailable(data !== undefined);
    };

    useEffect(() => {
        subscribeToInstantData((data) => {
            updateInstant(data);
        });
        return () => {
            console.log("Unsubscribing");
            unsubscribeFromInstantData(updateInstant);
        };
    }, []);

    const logoColor = isDataAvailable ? "#4E9F3D" : "#FF8C00";

    const symbols = [];
    if (latestData) {
        for (const sym in latestData.positionBalance) {
            const symName = sym; //sym.substring(0, sym.length - 4);
            symbols.push(
                <AssetGauge
                    key={sym}
                    symbol={symName}
                    position={latestData.positionBalance[sym]}
                />,
            );
        }
    }

    return (
        <div className="flex flex-row">
            <div className="mt-3 m-2">
                <div
                    className="p-1 text-align-center rounded align-middle border-2 border-gray-500"
                    style={{ backgroundColor: logoColor }}
                >
                    <div className="text-xl font-bold text-slate-700">
                        ス<br />ズ<br />メ
                    </div>
                </div>
            </div>
            <div className="flex flex-col mt-2">
                {latestData ? <MainGauge stats={latestData} /> : null}
                {symbols}
            </div>
        </div>
    );
}
